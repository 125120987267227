const idPortalApi = "c477ceec024015f68fc7d7bbcf4823ad40edeed6"; // Alto Parnaíba

module.exports = {
  idPortalApi: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,

  //config botões e imagens
  dnsPortal: "https://www.camaradealtoparnaiba.ma.gov.br",

  NomePortal: "Alto Parnaíba",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 4, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência

  urlEmDeploy: "https://www.camaradealtoparnaiba.ma.gov.br/", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#b28e08",
};
