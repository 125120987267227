import "../src/styles/global.css";
import Routes from "./routes";

import api from "./services/api";
import { useQuery } from "react-query";

export default function App() {
  const { data, isLoading, isError } = useQuery(
    "verify_portal",
    async () => {
      const { data } = await api.get("/portais/verify");
      return data;
    },
    { cacheTime: 60 * 60 * 24, staleTime: 60 * 60 * 24 } // 24 horas
  );

  if (isLoading) return <Routes />;

  if (isError || !data?.res) return <Routes />;

  if (data.res.isDisabled) {
    return <DisablePage DATA_DESATIVACAO={data.res.DATA_DESATIVACAO} />;
  }

  return <Routes />;
}

const DisablePage = ({ DATA_DESATIVACAO }) => {
  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src="/imagens/logo-work.png"
          alt="Workcenter"
          width="150px"
          height="150px"
          style={{
            width: "150px",
            height: "auto",
          }}
        />
        <img
          src="/imagens/logo.png"
          alt="LOGO PORTAL"
          style={{
            width: "150px",
            height: "auto",
          }}
        />
      </div>
      <h1
        style={{
          color: "#000080",
          fontSize: "36px",
        }}
      >
        Site em Manutenção
      </h1>
      {new Date(DATA_DESATIVACAO).toLocaleString("pt-BR", {
        dateStyle: "full",
      })}
    </main>
  );
};
